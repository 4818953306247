.content {
  color: var(--app-text-color);
  text-align: center;
  margin-bottom: 184px;

  h2 {
    font-size: 36px;
    font-weight: bold;
    color: #292d32;
    // line-height: 50px;
    margin-bottom: 68px;
  }

  >.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: -20px;
    margin-right: -20px;
  }

  >.mobileContainer {
    width: 100%;
    display: none;
  }

  .cardContainer {
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px #e3e6f3;
    border-radius: 28px;
    margin: 16px;
    padding: 32px;
    min-height: 350px;

    >.icon {
      display: inline-block;
      width: 64px;
      height: 64px;
      border-radius: 12px;
      background: #f1efff;
    }

    >.title {
      margin-top: 18px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      word-wrap: break-word;
      word-break: break-word;
      white-space: normal;
    }

    >.desc {
      font-size: 14px;
      font-weight: 400;
      color: #909093;
      word-wrap: break-word;
      word-break: break-word;
      white-space: normal;

      >a {
        color: var(--app-primary-color);
        font-weight: bold;

        &:hover {
          border-bottom: 1px solid var(--app-primary-color);
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .content {
    margin-bottom: 184px;

    >.container {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    margin-bottom: 154px;

    >.container {
      display: none;
    }

    >.mobileContainer {
      display: block;
    }

    .cardContainer {
      width: calc(100% - 32px);
      margin: 16px;
      margin-bottom: 32px;
      padding: 16px;
      height: 100%;
      min-height: 250px;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    margin-bottom: 119px;

    .cardContainer {
      min-height: 280px;
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    margin-bottom: 50px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }

    .cardContainer {
      min-height: 350px;
    }
  }
}
